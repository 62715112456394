import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Password } from 'primereact/password';
import RFG from "../../libs/inputs/RFG";
import { Card, CardBody, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import { LOGIN } from "../../services/actions";
export default function Login() {
  let dispatch = useDispatch()
  let userState = useSelector((state) => state?.login)
  const { handleSubmit, control } = useForm({
    defaultValues: { "username": "admin@gmail.com", password: "Admin@123" },
    mode: "all"
  })

  return (
    <>
      <div className="d-flex align-items-center justify-content-center" style={{ "height": "100vh" }}>
        <Card className="p-2" style={{ minWidth: 210 }}>
          <CardBody>
            <form autoComplete="false" onSubmit={handleSubmit((data) => {
              console.log("FORM", data)
              dispatch({ type: `${LOGIN}_AUTH`, payload: data });
            })}>
              <RFG name="username" label="Username" control={control} rules={{ required: true }} className="mb-2">
                <InputText autoComplete="false" />
              </RFG>
              <RFG name="password" label="Password" control={control} rules={{ required: true }} className="mb-2">
                <Password autoComplete="false" className="p-inputtext-sm w-100" feedback={false} toggleMask />
              </RFG>
              <Button className="w-100" type="submit">Login</Button>
            </form>
            <div className="error">{userState?.message}</div>
          </CardBody>
        </Card>
      </div>
    </>
  )
}