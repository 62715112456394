import {
  Card,
  CardBody,
  Button,
  Container,
  Col,
  Row,
  CardFooter,
  Stack,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { LOGIN, SETTINGS, LOANDUES } from "../../services/actions";
import { SignOut, Warning } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import React from "react";

import Badge from "react-bootstrap/Badge";
import depositsvg from "./deposits.svg";
import termsvg from "./term.svg";
import goldsvg from "./gold.svg";
import Flipcard from "./Flipcard";
import "../../App.css";
import ResponsiveText from "../../components/ResponsiveText";

export default function Dashboard() {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  let loanState = useSelector((state) => state?.loanduesState);
  let userState = useSelector((state) => state?.login);
  const [loans, setLoans] = React.useState();
  const [selectedLoans, setSelectedLoans] = React.useState(null);
  let settingsState = useSelector((state) => state?.settingsState);
  // let main = JSON.parse(localStorage.getItem("settings") || '{}')
  const [toggle, setToggle] = React.useState(0);
  const { handleSubmit, control } = useForm({
    defaultValues: { username: "admin@gmail.com", password: "Admin@123" },
    mode: "all",
  });
  let user = JSON.parse(localStorage.getItem("data") || "{}");
  React.useEffect(() => {
    try {
      dispatch({
        type: `${LOANDUES}_GET`,
        payload: user.customerID,
      });

      dispatch({ type: `${SETTINGS}_GET`, payload: 1 });
    } catch (err) {}
  }, []);
  // /Flipcard
  const handlePayment = (payAmount) => {
    console.log("Payment initiated for amount:", payAmount);
  };

  React.useEffect(() => {
    setLoans(loanState?.data?.data);
    console.log("LONAS:", loanState);
  }, [loanState?.data]);

  React.useEffect(() => {
    let mainStr = settingsState?.data?.data?.main;
    let main = typeof mainStr == "string" ? JSON.parse(mainStr) : mainStr;
    localStorage.setItem("settings", JSON.stringify(main));
  }, [settingsState]);

  function toLocalTime(utcDateString) {
    const utcDate = new Date(utcDateString);

    // Convert the UTC date to local time
    const localDate = utcDate.toLocaleString();
    return localDate;
  }

  return (
    <>
      <Container className="p-4" style={{ maxWidth: "400px" }}>
        <CardBody className="text-start">
          <div
            className=" justify-content-center  "
            style={{ maxWidth: 400, border: "none" }}
          >
            <div
              className="app-border app-container "
              style={{ maxWidth: 400, border: "none", marginBottom: "auto" }}
            >
              <div
                className="d-flex align-items-start flex-column"
                style={{ border: "none" }}
              >
                <div className="  w-100 px-2 ">
                  <div className="d-flex d-flex justify-content-between">
                    <h1 className="mb-4 mt-3 app-title">Payment Portal</h1>
                    <SignOut
                      size={20}
                      className="mt-4 text-danger cursor-pointer"
                      onClick={() => {
                        localStorage.setItem("data", null);
                        navigate("/", { replace: true });
                      }}
                    />
                  </div>
                  <form
                    autoComplete="false"
                    onSubmit={handleSubmit((data) => {
                      console.log("FORM", data);
                      dispatch({ type: `${LOGIN}_AUTH`, payload: data });
                    })}
                  >
                    <div className="my-2">
                      <p>
                        Welcome <b>{user?.customerName}</b>
                      </p>
                      <div className="my-2">
                        <ResponsiveText>
                          <h1 style={{ fontWeight: "norma" }}>
                          Select a Product and Account to make a payment
                          </h1>
                        </ResponsiveText>
                      </div>
                    </div>
                    {/* Account selection */}
                    <h6 className="mt-4 app-title2 text-start">
                      Financial Products
                    </h6>
                    <Container
                      className="d-flex justify-content-center mb-5"
                      position-fixed
                      position-md-static
                    >
                      <div className="d-flex justify-content-center align-items-center vh-50 w-100">
                        <Stack
                          direction="horizontal"
                          gap={3}
                          className="w-100"
                          style={{
                            justifyContent: "space-between",
                            position: "sticky",
                          }}
                        >
                          {loans?.installmentLoans?.length > 0 && (
                            <Card
                              border="primary"
                              style={{
                                flex: 1,
                                // height: "6rem",
                                // display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              className={`loan-box ${
                                toggle === 1 ? "loan-box-active" : ""
                              }`}
                              onClick={(e) => {
                                setSelectedLoans([...loans.installmentLoans]);
                                setToggle(1);
                              }}
                            >
                              <img src={termsvg} width={40} alt="Term Loan" />
                              <Card.Text
                                className="text-primary text-center mt-2"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                <ResponsiveText>
                                  <h1>Term Loans</h1>
                                </ResponsiveText>
                              </Card.Text>
                            </Card>
                          )}
                          {loans?.nonInstallmentLoans?.length > 0 && (
                            <Card
                              border="primary"
                              style={{
                                flex: 1,
                                // height: "6rem",
                                // display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              className={`loan-box ${
                                toggle === 2 ? "loan-box-active" : ""
                              }`}
                              onClick={(e) => {
                                setSelectedLoans([
                                  ...loans.nonInstallmentLoans,
                                ]);
                                setToggle(2);
                              }}
                            >
                              <img src={goldsvg} width={40} alt="Gold Loan" />
                              <Card.Text
                                className="text-primary text-center mt-2"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                <ResponsiveText>
                                  <h1>Gold Loan</h1>
                                </ResponsiveText>
                              </Card.Text>
                            </Card>
                          )}
                          {loans?.recurringDeposits?.length > 0 && (
                            <Card
                              border="primary"
                              style={{
                                flex: 1,
                                // height: "6rem",
                                // display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              className={`loan-box ${
                                toggle === 3 ? "loan-box-active" : ""
                              }`}
                              onClick={(e) => {
                                setSelectedLoans([...loans.recurringDeposits]);
                                setToggle(3);
                              }}
                            >
                              <img src={depositsvg} width={40} alt="Deposit" />
                              <Card.Text
                                className="text-primary text-center mt-2"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                <ResponsiveText>
                                  <h1>Deposit</h1>
                                </ResponsiveText>
                              </Card.Text>
                            </Card>
                          )}
                        </Stack>
                      </div>
                    </Container>

                    {/* End Account selection */}
                  </form>
                  {Array.isArray(selectedLoans) &&
                    selectedLoans.length == 0 && (
                      <div className="text-start">
                        No Accounts available for this product
                      </div>
                    )}
                  {Array.isArray(selectedLoans) &&
                    selectedLoans.map((loan, i) => (
                      <Card
                        key={i}
                        style={{ borderRadius: 10 }}
                        className="mb-2"
                      >
                        <CardBody style={{ border: "none" }}>
                          <Flipcard
                            loanTypeDesc={loan.loanTypeDesc}
                            Acc_number={loan.uniqueAccountNumber}
                            Disb_amt={loan.disbursedAmount}
                            Instmnt={loan.totalInstallments}
                            Due_amt={loan.dueAmount}
                            StartDate={toLocalTime(loan.startDate)}
                            MaturityDate={toLocalTime(loan.maturityDate)}
                            onPay={handlePayment}
                            pledgeNetWeight={loan.pledgeNetWeight}
                            Loan={loan}
                          />
                        </CardBody>
                      </Card>
                    ))}
                  <div
                    className="text-primary cursor-pointer mt-2  "
                    style={{ marginBottom: "100px" }}
                    onClick={() => {
                      navigate("/history/", {
                        state: {
                          index: 2,
                        },
                      });
                    }}
                  >
                    {" "}
                    Transaction history
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Container>
      <Footer />
    </>
  );
}
