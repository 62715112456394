import React from 'react';
import { ROLE } from "../../services/actions";
import { useDispatch, useSelector } from "react-redux";
import { ListBox } from 'primereact/listbox';
import { Button, Col, Container, Row } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import RFG from '../../libs/inputs/RFG';
import { InputText } from 'primereact/inputtext';
import { Cube, Pencil, Trash, Plus, Key } from "@phosphor-icons/react";
import { Dialog } from 'primereact/dialog';
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import Swal from 'sweetalert2';
import { AutoComplete } from "primereact/autocomplete";

export default function SearchableList({ header, list,
    optionLabel = "name", onClick,
    buttons, headerButtons = [], onSearch, onRowRender,
    listStyle = { maxHeight: '350px' } }) {
    const [selectedValue, setSelectedValue] = React.useState()
    const [filter, setFilter] = React.useState(list)
    React.useEffect(() => { setFilter(list) }, [list])
    const { handleSubmit, control, reset } = useForm({ defaultValues: {}, mode: "all" })
    const itemTemplate = (option) => {
        if (onRowRender) return onRowRender(option, buttons?.map((btn, idx) => <span onClick={() => { onClick(idx, option) }}>{btn}</span>))
        return (
            <div className="d-flex gap-1 align-items-center flex-wrapper w-100">
                <div><Cube fontSize={20} /></div>
                <div className="flex-grow-1">{option[optionLabel]}</div>
                <div className="d-flex gap-2 align-items-center">
                    {buttons?.map((btn, idx) => <span onClick={() => { onClick(idx, option) }}>{btn}</span>)}
                </div>
            </div>
        );
    };
    const filterTemplate = (option) => {
        return (<>
            <div className="d-flex align-items-center gap-1">
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search"> </InputIcon>
                    <InputText placeholder="Search" onChange={(e) => { search(e) }} />
                </IconField>
                {headerButtons.map((btn) => btn)}
                {!headerButtons && <Plus fontSize={24} color="green" className="cursor-pointer" onClick={() => { if (onClick) onClick("add") }} />}
            </div>
            {header}
        </>
        );
    };
    const search = (event) => {
        // Timeout to emulate a network connection
        console.log("event", event.target.value.length)
        setTimeout(() => {
            if (onSearch) {
                onSearch(event.target.value)
            } else {
                let _filtered;
                if (event.target.value.trim().length == 0) {
                    _filtered = [...list];
                } else {
                    _filtered = list.filter((obj) => {
                        return obj[optionLabel].toLowerCase().startsWith(event.target.value.toLowerCase());
                    });
                }
                setFilter(_filtered);
            }
        }, 250);
    }
    return (
        <>
            <ListBox
                filter
                value={selectedValue}
                onChange={(e) => setSelectedValue(e.value)}
                options={filter} optionLabel={optionLabel}
                itemTemplate={itemTemplate}
                filterTemplate={filterTemplate}
                className="w-full md:w-14rem"
                listStyle={listStyle}
            />

        </>
    )
}