import { useEffect, useState } from "react";

const useResponsiveFontSize = () => {
  const [fontSize, setFontSize] = useState("16px"); // Default font size

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      // Define font sizes for different screen widths
      if (screenWidth < 512) {
        setFontSize("14px"); // Small screens
      } else if (screenWidth < 768) {
        setFontSize("16px"); // Medium screens
      } else if (screenWidth < 1024) {
        setFontSize("18px"); // Large screens
      } else {
        setFontSize("20px"); // Extra large screens
      }
    };

    // Initial call to set font size
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return fontSize;
};

export default useResponsiveFontSize;