import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Row, Col, Card, CardBody } from "react-bootstrap";
import Footer from "../public_payment/Footer";
import bhim from "../../assets/bhim-app-icon.png";
import web from "../../assets/cashless-qr-code-payment-icon.png";
import gpay from "../../assets/google-pay-icon.png";
import paytm from "../../assets/paytm-icon.png";
import phonepe from "../../assets/phonepe-icon.png";
import upi from "../../assets/upi-icon.png";

const Payment = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();
  const orderState = useSelector((state) => state?.orderState);

  const icons = {
    bhim: bhim,
    gpay: gpay,
    paytm: paytm,
    phonepe: phonepe,
    web: web,
    default: upi,
  };

  return (
    <Container
      fluid
      className="g-0 p-4 d-flex justify-content-center align-items-center w-100"
      style={{  }}
    >
      <Row className=" d-flex justify-content-center ">
        <Col xs={12} sm={10} md={8} lg={6} xl={4} className="w-100">
          <Card className=" d-flex shadow-sm border-0">
            <CardBody className="text-start">
              <h2 className="mb-4 app-title text-center">Payment Portal</h2>
              <h5 className="text-secondary mb-3 text-center">
                Select a Payment Method
              </h5>
              <Row className="payment-icons mt-3">
                {orderState?.data?.data?.payload &&
                  Object.entries(orderState.data.data.payload).map(
                    ([key, url]) => (
                      <Col
                        xs={6}
                        sm={4}
                        className="text-center mb-3 d-flex justify-content-center align-items-center"
                        key={key}
                      >
                        <a
                          href={url}
                          style={{ textDecoration: "none" }}
                          className="d-flex flex-column align-items-center"
                        >
                          <img
                            src={icons[key] || icons.default}
                            alt={key}
                            style={{
                              width: "100%",
                              maxWidth: "80px",
                              height: "auto",
                              objectFit: "contain",
                            }}
                          />
                          <div
                            className="mt-2 text-dark text-center"
                            style={{
                              fontSize: "0.9rem",
                              wordBreak: "break-word",
                            }}
                          >
                            {key}
                          </div>
                        </a>
                      </Col>
                    )
                  )}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Footer />
    </Container>
  );
};

export default Payment;
