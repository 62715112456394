import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  CardBody,
  Button,
  Container,
  Col,
  Row,
  CardFooter,
  Stack,
  Form,
  Badge,
} from "react-bootstrap";
import "../../layouts/Flipcard.css";
import { useNavigate } from "react-router-dom";
const Flipcard = ({
  loanTypeDesc,
  Acc_number,
  Disb_amt,
  Due_amt,
  StartDate,
  MaturityDate,
  Instmnt,
  onPay,
  Loan,
  pledgeNetWeight,
}) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const [dueAmount, setDueAmount] = useState(Due_amt); // State to manage due amount
  const cardRef = useRef(null); // Reference to the card element
  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };
  const navigate = useNavigate();
  // Reset the card flip after 10 seconds
  useEffect(() => {
    let timer;
    if (isFlipped) {
      timer = setTimeout(() => {
        setIsFlipped(false);
      }, 20000); // 10 seconds
    }
    return () => clearTimeout(timer); // Cleanup timeout on unmount or flip state change
  }, [isFlipped]);

  // Reset dueAmount to the initial Due_amt when the card resets or flips
  useEffect(() => {
    if (!isFlipped) {
      setDueAmount(Due_amt);
    }
  }, [isFlipped, Due_amt]);

  const handlePayButtonClick = (event) => {
    event.stopPropagation(); // Prevent the flip when the Pay button is clicked
    if (onPay) {
      onPay(dueAmount); // Pass the dueAmount to the parent component
    }
    console.log("Pay button clicked");
    console.log({ dueAmount });
    console.log({ onPay });
    navigate("/order/", {
      state: {
        ...Loan, // Spread the loan object and pass it as state
        dueAmount, // Optionally, you can include the modified dueAmount here
      },
    });
  };
  const handleInputChange = (event) => {
    event.stopPropagation();
    setDueAmount(event.target.value); // Update state on input change
  };
  const handleOutsideClick = (event) => {
    if (cardRef.current && !cardRef.current.contains(event.target)) {
      setIsFlipped(false); // Flip the card back to its initial state
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div className="d-flex justify-content-center align-items-center vh-10">
      <div
        ref={cardRef}
        className={`credit-card ${isFlipped ? "flipped" : ""}`}
        onClick={handleFlip}
      >
        {/* Front of the card */}

        <div className="card-front">
          <Stack
            direction="horizontal "
            className="position-absolute top-0 end-0 m-2"
            gap={2}
          >
            <Badge bg="info">Tap to pay</Badge>
          </Stack>
          <span>
            <div className="card-brand mt-2" 
            // style={{ borderBottom: "1px solid gray", paddingBottom: "2px" }}
            >{loanTypeDesc}</div>
            
            <div className="card-text">
              <span className="spaced-text">A/c No:</span>
              {Acc_number}
            </div>{" "}
            <div className="card-strip"></div>
            <div className="card-text-bold">
              <span>Due Amount : </span>
              {Due_amt}
            </div>
            {Instmnt && (
            <div className="card-text-bold">
              <span>Installement : </span>
              {Instmnt}
            </div>  
            )}
            {Disb_amt!== null && Disb_amt !== undefined && Disb_amt !== 0 &&(
            <div className="card-text">
              <span>Amount&nbsp;:&nbsp;</span>
              {Disb_amt}
            </div>
          )}
            <div className="card-text">
              <span>Open Date&nbsp;:&nbsp;</span>
              {StartDate}
            </div>
            
            {pledgeNetWeight &&(
            <div className="card-text">
              <span>Pledge Net Weight&nbsp;:&nbsp;</span>
              {pledgeNetWeight}
              <span>&nbsp;grams&nbsp;</span>
            </div>
            )}
          </span>
        </div>

        {/* Back of the card */}
        <div className="card-back">
          
          
          <div className="card-back-title" 
          // style={{ borderBottom: "1px solid gray", paddingBottom: "2px" }}
          >Make Payment</div>
          <div className="card-brand mt-2">{loanTypeDesc}</div>
          <div className="card-text">
            <span className="spaced-text">A/c No:</span>
            {Acc_number}
          </div>
          <div className="card-strip"></div>
          <div className="mx-2">
         
          {/* <div className="card-back-accno">{Acc_number}</div> */}
          <div className="card-text-bold">
            <span>Due Amount : </span>
            {Due_amt}
          </div>
          {Disb_amt!== null && Disb_amt !== undefined && Disb_amt !== 0 &&(
            <div className="card-text">
              <span>Amount&nbsp;:&nbsp;</span>
              {Disb_amt}
            </div>
          )}
          </div>
          {/* Input box with default value */}
          <Stack direction="horizontal" gap={3}>
            <label
              htmlFor="due-amount"
              style={{ marginLeft: "1px", fontSize: "12px" }}
            >
              Amount:</label>
            <input
              id="due-amount"
              type="text"
              onClick={handleInputChange}
              onChange={handleInputChange}
              value={dueAmount}
              className="form-control"
              style={{
                width: "100%",
                fontSize: "12px",
                display: "inline-block",
                
                margin: "1px",
              }}
            />

            <Button variant="success  px-4  " size="small" onClick={handlePayButtonClick}>
              Pay
            </Button>
            <div  />
          </Stack>
          {/* <div className="card-back-accno" style={{ width: "100%", boxSizing: "border-box", padding: "10px" }}>
            <label htmlFor="due-amount" style={{ marginLeft:"10px"}}>Due Amount:</label>
            <input
              id="due-amount"
              type="text"
              onClick={handleInputChange}
              onChange={handleInputChange}
              value={dueAmount}
              className="form-control"
              style={{ width: "100%",fontSize:"15px", display: "inline-block", margin:"1px"}}
            />
            <Button className="mr-auto app-btn-primary"  
            style={{ width: '100%',paddingTop:"5px",marginTop :"5px", overflow: "hidden",marginBottom: "5px"}}onClick={handlePayButtonClick} >Pay</Button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Flipcard;
