import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import RFG from "../../libs/inputs/RFG";
import {
  Card,
  CardBody,
  Button,
  Container,
  Col,
  Row,
  CardFooter,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { PAYMENT } from "../../services/actions";
import {
  CalendarDots,
  Clock,
  CurrencyInr,
  Warning,
  X,
} from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import React from "react";
import { ToggleButton } from "primereact/togglebutton";
import Badge from "react-bootstrap/Badge";
import depositsvg from "./deposits.svg";
import termsvg from "./term.svg";
import goldsvg from "./gold.svg";
import closeGif from "../../assets/btn_close_animated.gif";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export default function History() {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  let paymentState = useSelector((state) => state?.paymentState);
  let user = JSON.parse(localStorage.getItem("data") || "{}");
  // console.log("local-storage",user)
  const [history, setHistory] = React.useState([]);

  React.useEffect(() => {
    try {
      dispatch({
        type: `${PAYMENT}_GET`,
        payload: `payments/0/?customer_number=${user.customerID}`,
      });
    } catch (err) {}
  }, []);

  React.useEffect(() => {
    const group = {};
    paymentState?.data?.data?.results.map((row, i) => {
      let d = new Date(row.created_at);
      let options = { year: "numeric", month: "short" };
      let ym = d.toLocaleString("en-US", options);
      row.day = d.toLocaleString("en-US", { day: "2-digit" });
      row.time = d.toLocaleString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
      let groups = group[ym] ? group[ym].push(row) : (group[ym] = [row]);
    });
    setHistory(group);
    console.log("History", group);
  }, [paymentState?.data]);

  function toLocalTime(utcDateString) {
    const utcDate = new Date(utcDateString);
    // Convert the UTC date to local time
    const localDate = utcDate.toLocaleString();
    return localDate;
  }

  return (
    <>
      <div className=" app-container " style={{}}>
        <div
          className="d-flex align-items-start flex-column pb-5"
          style={{ minHeight: "100vh" }}
        >
          <div className="d-flex justify-content-between mx-4 mt-5">
            <h1 className="mb-5 app-title d-flex  justify-content-center">
              Payment Portal
            </h1>
            {/* <X className="cursor-pointer text-danger" size={20} onClick={() => { navigate('/dashboard/') }} /> */}
          </div>
          <div className="d-flex gap-4 justify-content-center w-100 mt-2   ">
            <h5
              className=" d-flex  justify-content-center"
              style={{ color: "gray" }}
            >
              Transaction History
            </h5>
          </div>
          <div className=" w-100 d-flex justify-content-end  gap-4">
            <Button
              variant="outline-light"
              className="  m-2 d-flex align-items-center"
              onClick={() => navigate("/dashboard/")}
            >
              <img
                src={closeGif}
                alt="Back"
                style={{ width: 30, height: 30, color: "white" }}
              />
              <span className="ms-2">Back</span>
            </Button>
          </div>
          {/* <Button className="btn app-btn-danger px-4" onClick={()=>{window.close ()}}>Cancel</Button> */}
          <div className=" w-100 mt-5">
            {Array.isArray(paymentState?.data?.data?.results) &&
              paymentState.data.data.results.length == 0 && (
                <div className="text-center ">No loans available</div>
              )}
          </div>
          {Object.keys(history)?.map((key, i) => {
            return (
              <>
                {Object.keys(history)?.map((key, i) => (
                  <Container key={i} className="w-100 mb-5 pb-2">
                    <h6 className="mb-3">{key}</h6>
                    {history[key]?.map((row, k) => {
                      let STATUS = {
                        PENDING: { text: "Pending", _class: "text-warning" },
                        SUCCESS: { text: "Success", _class: "text-success" },
                        USER_DROPPED: {
                          text: "User dropped",
                          _class: "text-primary",
                        },
                        FAILED: { text: "Failed", _class: "text-danger" },
                      };
                      let _class = STATUS[row.payment_status]?._class;
                      let text =
                        STATUS[row.payment_status]?.text || row.payment_status;

                      return (
                        <Card
                          key={k}
                          className="mb-1 p-3 shadow-sm"
                          onClick={() => {
                            window.open(
                              `${window.location.origin}/api/redirect/${row?.order_id}/?from=history`,
                              "_blank"
                            );
                          }}
                          onMouseEnter={(e) =>
                            (e.currentTarget.style.backgroundColor = "#eff2f7")
                          }
                          onMouseLeave={(e) =>
                            (e.currentTarget.style.backgroundColor = "white")
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <Row className="align-items-center g-1">
                            <Col xs={12} md={6} className="mb-1">
                              <div>
                                <b>Order ID:</b> {row?.order_id}
                              </div>
                              <div>
                                <b>Acc No:</b> {row?.loan_number}
                              </div>
                              <div className="d-flex align-items-center gap-2 text-muted">
                                <CalendarDots size={16} />
                                {row.day} | <Clock size={16} />
                                {row.time}
                              </div>
                              <div>{row.customer_note}</div>
                            </Col>
                            <Col xs={6} md={3} className="text-md-center">
                              <div>
                                <b
                                  className={`d-flex gap-0 align-items-center ${_class}`}
                                >
                                  <CurrencyInr size={16} />
                                  {row.payment_amount}
                                </b>
                              </div>
                              <div
                                className={`d-flex gap-0 align-items-center  ${_class}`}
                                style={{ fontSize: 14 }}
                              >
                                {text}
                              </div>
                            </Col>
                            <Col xs={6} md={3} className="text-md-end">
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`${window.location.origin}/api/redirect/${row?.order_id}/?from=history`}
                              >
                                View Receipt
                              </a>
                            </Col>
                          </Row>
                        </Card>
                      );
                    })}
                  </Container>
                ))}
              </>
            );
          })}
        </div>
      </div>
      <Footer />
    </>
  );
}
