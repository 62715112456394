import { size } from 'lodash';
import React, { cloneElement, Children } from 'react'
import { useForm, useController, UseControllerProps } from "react-hook-form"
export default function RFG(props) {
    const { children, label, register, name, required,errormessage } = props;
    const { field, fieldState } = useController(props)
    return (
        <div>
            {Children.map(children, child => {
                return cloneElement(child, {
                    inputId: name,
                    placeholder: label,
                    ...field
                })
            })}

            <div className="error mb-2 mt-1 " >
                <p
                className="medium notes mb-1">
                {fieldState?.error?fieldState?.error?.message:null}
                {/* {fieldState?.error?.type=='required'?`${name}  required`:null} */}
                {/* {fieldState?.error?.type=='required'?`  *Required`:null} */}
                {fieldState?.error?.type=='required'?`${errormessage?errormessage:('Required')}`:null}
                </p>
                </div>
        </div>
    )
}
