
import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useDispatch, useSelector } from "react-redux";
import * as ACTION from "../../services/actions";
import { Badge } from 'react-bootstrap';
import Profile from '../auth/Profile';
import { Dialog } from 'primereact/dialog';
import UserForm from '../auth/UserForm';


export default function Users() {
    const dispatch = useDispatch()
    let userState = useSelector((state) => state?.userState)

    const [openDlg, setOpenDlg] = React.useState()

    useEffect(() => {
        dispatch({ type: `${ACTION.USER}_GET`, payload: 0 });
    }, []);

    const columns = [
        { field: 'first_name', header: 'First Name' },
        { field: 'last_name', header: 'Last Name' },
        { field: 'avatar', header: 'Photo' },
        { field: 'username', header: 'Email' }
    ];
    const onSubmit = (data) => {
        if (!data['avatar'] || String(data['avatar']).startsWith("http")) {
            delete data['avatar']
        }
        console.log("Submiteed", data)
        let action = data?.id ? `${ACTION.USER}_PATCH` : `${ACTION.USER}_POST`
        dispatch({
            type: action, payload: data, callback: () => {
                dispatch({ type: `${ACTION.USER}_GET`, payload: 0 });
            }
        });
    }
    return (
        <>
            <Dialog header={"User"} style={{ width: '50vw', maxWidth: "400px", marginLeft: 20, marginRight: 20 }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}
                visible={openDlg} onHide={() => { setOpenDlg(false) }}>
                <UserForm onSubmit={onSubmit} />
            </Dialog>

            <div className="card p-2 m-2">
                <div className='py-3 d-flex justify-content-between align-items-center'>
                    <div>Users list</div> <Badge onClick={() => { setOpenDlg(true) }}>Add + </Badge>
                </div>
                <DataTable value={userState?.data?.data?.results} tableStyle={{ minWidth: '50rem' }}>
                    {columns.map((col, i) => (
                        <Column key={col.field} field={col.field} header={col.header} />
                    ))}
                </DataTable>
            </div>
        </>
    );
}
