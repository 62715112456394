// ---  Should  not update this code----
import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
import createSagaMiddleware from 'redux-saga';
import { createReducer, createSaga } from "../libs/redux/saga/index";
import { REQUESTS } from './actions.js';

// const rootReducer = combineReducers({ login: login, user: user, punch: punch })
const rootReducer = combineReducers({...createReducer(REQUESTS)})
function* rootSaga() {
    yield all([...createSaga(REQUESTS)]);
}

const sagaMiddleware = createSagaMiddleware();
const store = compose(
    applyMiddleware(sagaMiddleware)
)(createStore)(rootReducer);
sagaMiddleware.run(rootSaga);
export default store;
// ---  Should  not update this code----