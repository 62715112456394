import React, { useState } from "react";
import axios from "axios";
import { ORDER, ORDERCONFIRM } from "../../services/actions";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Row,
  Stack,
} from "react-bootstrap";
import RFG from "../../libs/inputs/RFG";
import { InputText } from "primereact/inputtext";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { LOGIN } from "../../services/actions";
import { Check } from "@phosphor-icons/react";
import { useNavigate, useLocation } from "react-router-dom";
import Footer from "../public_payment/Footer";
import { Dialog } from "primereact/dialog";
import ResponsiveText from "../../components/ResponsiveText";

const Order = () => {
  const [loading, setLoading] = useState(false);
  const [orderId, setOrderId] = useState("");
  let dispatch = useDispatch();
  const { state } = useLocation();

  const navigate = useNavigate();
  let orderState = useSelector((state) => state?.orderState);
  let orderConfirmState = useSelector((state) => state?.orderconfirmState?.data);
  const [visible, setVisible] = useState(false);
  let user = JSON.parse(localStorage.getItem("data") || "{}");

  const { handleSubmit, control, reset, getValues } = useForm({
    mode: "all",
    defaultValues: { order_amount: 0, customer_phone: localStorage.mobile },
  });

  React.useEffect(() => {
    // reset({ order_amount: state?.amount })
  }, [orderState?.data]);
  React.useEffect(() => {
    dispatch({
      type: `${ORDERCONFIRM}_POST`,
      payload: {
        uniqueAccountNumber: state?.uniqueAccountNumber,
        moduleCode: state?.moduleCode,
        payment_amount: state?.dueAmount,
      },
    });
    console.log("order-confirm",orderConfirmState)
  }, [state]);
  React.useEffect(() => {
    reset({
      ...getValues(),
      order_amount: state?.dueAmount,
      loan_number: state?.uniqueAccountNumber,
      customer_phone: localStorage.mobile,
    });
  }, [state]);
  function toLocalTime(utcDateString) {
    const utcDate = new Date(utcDateString);

    // Convert the UTC date to local time
    const localDate = utcDate.toLocaleDateString();
    return localDate;
  }

  const handlePayment = async (data) => {
    setLoading(true);
    try {
      let payload = {
        order_id: state?.uniqueAccountNumber,
        ...data,
        customer_phone: user.cellNumber,
        customer_id: user.customerID,
        customer_name: user.customerName,
        branch_id: state?.branchID,
        module_code: state?.moduleCode,
      };

      dispatch({
        type: `${ORDER}_POST`,
        payload: payload,
        callback: (res) => {
          navigate("/payment/", {
            state: { res, ...state },
          });
        },
      });
    } catch (error) {
      console.error("Payment error:", error);
    } finally {
      setLoading(false);
    }
  };
  const icons = {
    bhim: "https://th.bing.com/th?id=OSAAS.DBC88DCAA4D4B1072E05FD6F08034545&w=72&h=72&c=17&rs=1&o=6&pid=5.1",
    gpay: "https://w7.pngwing.com/pngs/667/120/png-transparent-google-pay-2020-hd-logo.png",
    paytm:
      "https://d1.awsstatic.com/Paytm-Logo.516dcbea24a48dc1f0187700fbd0f6a48f9a18c3.png",
    phonepe:
      "https://www.vhv.rs/dpng/d/411-4117619_phonepe-logo-png-phonepe-logo-transparent-background-png.png",
    web: "https://thumbs.dreamstime.com/b/qr-code-icon-vector-isolated-transparent-background-254411299.jpg",
    default:
      "https://arpitatulsyan.com/wp-content/uploads/2020/03/upi-logo-png-4.png",
  };

  return (
    <>
      {/* {JSON.stringify(user)} */}
      <Container fluid className="px-3 mt-4 ">
        <Row className="justify-content-center ">
          <Col xs={12} sm={10} md={8} lg={6} xl={5}>
            <h1 className="mb-4 ms-4 app-title">Payment Portal</h1>
            <Card className="p-3">
              <CardBody>
                <form
                  autoComplete="false"
                  onSubmit={handleSubmit((data) => {
                    handlePayment(data);
                  })}
                >
                  {/* <ResponsiveText> */}
                    <h3 className="text-dark"style={{fontSize:"14px"}}>Payment Confirmation </h3>
                    <p className="text-muted mb-4 " style={{fontSize:"12px"}}>
                    {"\u20B9"}<strong>{state?.dueAmount?.toLocaleString("en-IN")}</strong> will
                      be Credited to Account-{" "}
                      <strong>{state?.uniqueAccountNumber}</strong>
                    </p>
                    {/* {state.installment && (
                      <p className="text-center">
                        Installment -{" "}
                        <strong>
                          {state.installment} / {state.totalInstallments}
                        </strong>
                      </p>
                    )} */}
                    {/* {orderConfirmState?.data?.revision_data?.revisedDate && (
                    <div className="text-muted "> <span>Intrest paid up to : </span> {toLocalTime(orderConfirmState?.data?.revision_data?.revisedDate)}</div>
                    )} */}
                    {/* </ResponsiveText> */}
                  <Stack
                    gap={2}
                    direction="horizontal"
                    className="mt-4 d-flex justify-content-center"
                  >
                    {/* <Button
                      className="btn btn-primary w-100 w-md-auto px-4"
                      type="submit"
                    >
                      Proceed
                    </Button> */}
                      <Button
                      className="btn btn-danger  w-md-auto px-4"
                      onClick={() => navigate("/dashboard/")}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="px-4 w-md-auto"
                      type="submit"
                      sx={{
                        backgroundColor: "var(--primary-color)",
                        color: "var(--text-color)",
                        "&:hover": { backgroundColor: "var(--primary-hover)" },
                      }}
                    >
                      Proceed
                    </Button>
                  
                  </Stack>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Order;
