import React from 'react'
import Cropper from 'react-easy-crop'
import './style.css'
import { Slider } from 'primereact/slider'
import { Button } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import { Avatar } from 'primereact/avatar';
import getCroppedImg, { getOrginalImage } from './cropImg';

export default function Image(props) {
    const [crop, setCrop] = React.useState({ x: 0, y: 0 })
    const [zoom, setZoom] = React.useState(1)
    const [visible, setVisible] = React.useState(false);
    const [rotation, setRotation] = React.useState(0)
    const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null)
    const [croppedImage, setCroppedImage] = React.useState(null)
    const [image, setImage] = React.useState(props?.src)
    const [files, setFiles] = React.useState([])
    const [imgIdx, setImgIdx] = React.useState(0)

    const onCropChange = (crop) => {
        setCrop(crop)
    }

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        console.log(croppedAreaPixels.width / croppedAreaPixels.height)
        setCroppedAreaPixels(croppedAreaPixels)
    }
    const showCroppedImage = async () => {
        try {
            const croppedImage = await getCroppedImg(
                image,
                croppedAreaPixels,
                rotation
            )
            setCroppedImage(croppedImage)
            files[imgIdx].crop = croppedImage
            setFiles([...files])
            props?.onChange(croppedImage)
        } catch (e) {
            console.error(e)
        }
    }

    const onZoomChange = (zoom) => {
        setZoom(zoom)
    }
    const readFile = (e) => {
        setFiles([])

        let inputFiles = e.target.files
        if (!props.multiple) {
            inputFiles = [e.target.files[0]]
        }
        for (const file of inputFiles) {
            var reader = new FileReader();
            reader.onload = function (e) {
                if (props.multiple) {
                    files.push({ file: e.target.result, crop: null })
                    setFiles([...files])
                } else {
                    props?.onChange(e.target.result)
                    setFiles([{ file: e.target.result, crop: null }])
                }
                console.log("files", files?.length)
            };
            reader.readAsDataURL(file);
        }
        e.target.value = ""
    }

    const headerElement = (
        <div>
            <Avatar image="https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png" shape="circle" />

        </div>
    );

    const footerContent = (
        <div>
            <Button size="small" onClick={() => { setVisible(false); showCroppedImage() }} autoFocus >OK</Button>
        </div>
    );

    return (
        <>
            <Dialog visible={visible} style={{ height: "320px", minWidth: "320px" }} header={headerElement} footer={footerContent} onHide={() => { if (!visible) return; setVisible(false); }}>
                <div className="crop-container">
                    <Cropper
                        image={image}
                        crop={crop}
                        zoom={zoom}
                        aspect={1}
                        cropShape="round"
                        showGrid={false}
                        onCropChange={onCropChange}
                        onCropComplete={onCropComplete}
                        onZoomChange={onZoomChange}
                    />
                </div>
            </Dialog>

            <div>

            </div>
            <div>
                {files?.map((file, idx) => <>
                    <span>
                        <img onClick={() => { setImage(file?.file); setImgIdx(idx); setVisible(true); }} src={file?.crop ? file?.crop : file?.file} width="80" />
                        <label htmlFor={props.name}>Edit</label>
                    </span>
                </>)}
                <div>
                    {props.multiple && <label htmlFor={props.name}>
                        {imgIdx} + Add New File
                    </label>}
                    {(files.length == 0 && !props.multiple) && <label htmlFor={props.name}>
                        <Avatar label={"AD"} image={props?.src} size="xlarge" shape={props?.shape} />
                    </label>}
                </div>
            </div>

            <input style={{ display: 'none' }} type="file" id={props.name} onChange={(e) => readFile(e)} />
        </>
    )
}
