
import { Card, CardBody, Button, Container, Col, Row } from "react-bootstrap";
import { Accordion, AccordionTab } from 'primereact/accordion';
import Footer from "./Footer";
import { useNavigate, useLocation } from "react-router-dom";
import closeGif from "../../assets/btn_close_animated.gif";

export default function TermsConditiions() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const privacypolicy = () => {
    try {
      let main = JSON.parse(localStorage.getItem("settings") || '{}')
      return <div dangerouslySetInnerHTML={{ __html: main?.privacypolicy }}></div>
    } catch (error) {

    }
  }

  const disclaimer = () => {
    try {
      let main = JSON.parse(localStorage.getItem("settings") || '{}')
      return <div dangerouslySetInnerHTML={{ __html: main?.disclaimer }}></div>
    } catch (error) {

    }
  }

  const termsAndCOnditions = () => {
    try {
      let main = JSON.parse(localStorage.getItem("settings") || '{}')
      return <div dangerouslySetInnerHTML={{ __html: main?.termsconditions }}></div>
    } catch (error) {

    }
  }

  return (
    <>
      <Container fluid className="g-0 w-100 mb-4  vh-100 ">
        <Row className="d-flex justify-content-center g-0 ">
          {/* <Col xs={12} sm={8} md={5} xl={4}> */}
            <Card className="px-2 border-none" style={{ minWidth: 210, minHeight: '100vh' }}>
              <div className="px-4">
                <h1 className="mb-2 mt-4 app-title">Payment Portal</h1>
              </div>
              <div className="d-flex justify-content-end mb-4 gap-4">              
              <Button variant="outline-light" className="  px-4 mt-5 d-flex align-items-center" onClick={() => navigate("/")}>
      <img src={closeGif} alt="Close" style={{ width: 30, height: 30, color:"white" }} />
      <span className="ms-2">Back</span>
    </Button>
    </div>
              <Accordion  activeIndex={state?.index}>
                <AccordionTab  header="Privacy Policy">
                  <p className="m-4">
                    {privacypolicy()}
                  </p>
                </AccordionTab>
                <AccordionTab header="Disclaimer">
                  <p className="m-4">
                    {disclaimer()}
                  </p>
                </AccordionTab>
                <AccordionTab header="Terms & Conditions">
                  <p className="m-4">
                    {termsAndCOnditions()}
                  </p>
                </AccordionTab>
              </Accordion>
              
              
            </Card>
          {/* </Col> */}
        </Row>
        <Footer />
      </Container >
    </>
  )
}