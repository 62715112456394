import React from 'react';
import { Calendar } from "primereact/calendar";
import { AutoComplete } from "primereact/autocomplete";
import { Badge } from 'primereact/badge';
import { Card, CardBody, Container, Row, Col, Button, Spinner } from "react-bootstrap";
import Image from "../../libs/inputs/Image";
import { Avatar } from "primereact/avatar";
import { USER } from "../../services/actions";
import { useDispatch, useSelector } from "react-redux";
import { InputText } from 'primereact/inputtext';
import RFG from '../../libs/inputs/RFG';
import { useForm } from 'react-hook-form';

export default function UserForm({ data = {}, onSubmit = () => { } }) {
    const { handleSubmit, control, reset, getValues } = useForm({ mode: "all" })

    React.useEffect(() => {
        console.log("USER STATE", data)
        if (Boolean(data)) reset(data)

    }, [data?.id])

    return (
        <>
            <Card>
                <CardBody>
                    <div className="mb-3 text-center">
                        <Image
                            name="avatar"
                            src={data?.avatar}
                            icon={<Avatar label="A" size="xlarge" shape="circle" className="mb-3" />}
                            onChange={(image) => { reset({ ...getValues(), avatar: image }) }}
                            shape="circle"
                        />
                        <div>{data?.first_name} {data?.main_role}</div>
                        <Badge value={`Main Role: ${data?.main_role ? data.main_role : "Not Updated"}`} size="small" severity="warning" />
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <RFG name="avatar" control={control}>
                            <input type="hidden" />
                        </RFG>
                        <RFG name="id" control={control}>
                            <input type="hidden" />
                        </RFG>
                        <RFG name="first_name" label="First Name" control={control} rules={{ required: true }} className="mb-2">
                            <InputText />
                        </RFG>
                        <RFG name="last_name" label="Last Name" control={control} rules={{ required: true }}>
                            <InputText />
                        </RFG>
                        <RFG name="email" label="Email" control={control} rules={{ required: true }}>
                            <InputText />
                        </RFG>
                        <RFG name="phone" label="Phone" control={control} rules={{ required: true }}>
                            <InputText />
                        </RFG>
                        <hr />
                        <RFG name="username" label="Email" control={control} rules={{ required: true }}>
                            <InputText />
                        </RFG>

                        <RFG name="password" label="Password" control={control} rules={{ required: true }}>
                            <InputText />
                        </RFG>
                        <input type="submit" className={'basic-btn'} />
                    </form>
                </CardBody>
            </Card>
        </>
    )
}