import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";

import Layout from './layouts/Layout';
import AnonyLayout from './layouts/AnonyLayout';
import { menus, anonyMenus } from './layouts/menus';
import { useDispatch, useSelector } from "react-redux";
import { LOGIN, LOGOUT } from "./services/actions";
import { useNavigate } from "react-router-dom";
import axios from 'axios';


function App() {
  let dispatch = useDispatch()
  const navigate = useNavigate()
  let auth = useSelector((state) => state?.loginState?.auth)
  React.useEffect(() => {
    dispatch({ type: `${LOGIN}_AUTH`, payload: null, local: true });
  }, [])
  const onLogout = () => {
    dispatch({ type: `${LOGOUT}_AUTH`, payload: null });
    navigate("/")
  }
  return (<>
    {auth?.token && <Layout menus={menus}
      onLogout={() => { onLogout() }}
      appName={<label className="text-center">{process.env.REACT_APP_PROJECT_NAME}</label>}
      ispushsidebar={true}
    />}
    {!auth?.token && <AnonyLayout menus={anonyMenus} />}

  </>
  );
}

export default App;
