import React from 'react';
import { Calendar } from "primereact/calendar";
import { AutoComplete } from "primereact/autocomplete";
import { Badge } from 'primereact/badge';
import { Card, CardBody, Container, Row, Col, Button, Spinner } from "react-bootstrap";
import Image from "../../libs/inputs/Image";
import { Avatar } from "primereact/avatar";
import { SETTINGS } from "../../services/actions";
import { useDispatch, useSelector } from "react-redux";
import { InputText } from 'primereact/inputtext';
import RFG from '../../libs/inputs/RFG';
import { useForm } from 'react-hook-form';
import { Editor } from 'primereact/editor';

export default function Settings() {
    let dispatch = useDispatch()
    let user = useSelector((state) => state?.loginState.auth)
    let settingsState = useSelector((state) => state?.settingsState)
    const { handleSubmit, control, reset, getValues } = useForm({ defaultValues: {}, mode: "all" })
    const [avatar, setAvatar] = React.useState()
    const [main, setMain] = React.useState()

    React.useEffect(() => {
        dispatch({ type: `${SETTINGS}_GET`, payload: "settings/1/" });
        console.log("Settings init", settingsState?.data)
    }, [])

    React.useEffect(() => {
        let prev = settingsState?.data?.data
        let main = typeof prev?.main == 'string' ? JSON.parse(prev?.main) : prev?.main
        let branch = typeof prev?.branches == 'string' ? JSON.parse(prev?.branches) : prev?.branches
        reset({ id: prev?.id, ...main, branches: branch })
        console.log(prev)
        setMain(main)
    }, [settingsState])

    const onSubmit = (data) => {
        if (!data['icon'] || String(data['icon']).startsWith("http")) {
            delete data['icon']
        }
        let redata = {
            id: data?.id,
            branches: data?.branches,
            main: {
                icon: data?.icon,
                name: data?.name,
                termsconditions:data?.termsconditions,
                privacypolicy:data?.privacypolicy,
                disclaimer:data?.disclaimer,
            }
        }
        console.log("Submitted Data", redata)
        // console.log("Submitted Data frorm", getValues())

        let action = redata.id ? `${SETTINGS}_PATCH` : `${SETTINGS}_POST`
        dispatch({ type: action, payload: redata });
    }

    return (
        <>
            <Container fluid className="py-3">
                <Row>
                    <Col sm={12} md={6}>
                        <Card>
                            <CardBody>
                                {settingsState.loading && <Spinner />}

                                <div className="mb-3 text-center">
                                    <Image name="avatar"
                                        src={main?.icon}
                                        icon={<Avatar label="A" size="xlarge" shape="circle" src={user?.avatar} className="mb-3" />}
                                        onChange={(data) => { reset({ ...getValues(), icon: data }) }}
                                    />
                                </div>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <RFG name="id" control={control}>
                                        <input type="hidden" />
                                    </RFG>
                                    <RFG name="name" label="Company Name" control={control} rules={{ required: true }} className="mb-2">
                                        <InputText />
                                    </RFG>
                                    <RFG name="privacypolicy" label="Privacy Policy" control={control}  className="mb-2">
                                        <Editor onTextChange={(e) => { reset({ ...getValues(), privacypolicy: e.htmlValue }) }} style={{ height: '320px' }} />
                                    </RFG>
                                    <RFG name="disclaimer" label="Disclaimer" control={control}  className="mb-2">
                                        <Editor onTextChange={(e) => { reset({ ...getValues(), disclaimer: e.htmlValue }) }} style={{ height: '320px' }} />
                                    </RFG>
                                    <RFG name="termsconditions" label="Terms and Conditions" control={control}  className="mb-2">
                                        <Editor onTextChange={(e) => { reset({ ...getValues(), termsconditions: e.htmlValue }) }} style={{ height: '320px' }} />
                                    </RFG>
                                    <input type="submit" className={'basic-btn'} />
                                </form>
                                {settingsState.message}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </>
    )
}