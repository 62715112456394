import { Horse, Heart, Cube, WindowsLogo, Trash, Key, CurrencyCircleDollar, UserGear, User, CirclesThreePlus } from "@phosphor-icons/react";

import Profile from "../pages/auth/Profile"
import CheckInOut from "../pages/punching/CheckInOut"
import Role from "../pages/permissions/Role"
import Login from "../pages/auth/Login"
import Home from "../pages/public_payment/Home";
import OTP from "../pages/public_payment/OTP";
import Warnings from "../pages/public_payment/Warnings";
import CashFreeWarnings from "../pages/cashfree/Warnings";
import PaymentDashboard from "../pages/public_payment/Dashboard";
import Order from "../pages/cashfree/Order";
import Payment from "../pages/cashfree/Payment";
import Settings from "../pages/auth/Settings";
import SearchableList from "../libs/list/SearchableList"
import TermsConditiions from "../pages/public_payment/TermsConditiions";
import UserRole from "../pages/permissions/UserRole";
import Users from "../pages/users/Users";
import History from "../pages/public_payment/History";
import Payments from "../pages/public_payment/Payments";
import Dashboard from "../pages/dashbord/Dashboard";


export const anonyMenus = [
    {
        to: "/",
        // component: <Order />,
        component: <Home />,
    },
    {
        to: "/login/",
        component: <Login />,
    },
    {
        to: "/otp/",
        component: <OTP />,
    },
    {
        to: "/warning/",
        component: <Warnings />,
    },
    {
        to: "payment/warning/",
        component: <CashFreeWarnings />,
    },
    {
        to: "/order/",
        component: <Order />,
    },
    {
        to: "/payment/",
        component: <Payment />,
    },
    {
        to: "/history/",
        component: <History />,
    },
    {
        to: "/dashboard/",
        component: <PaymentDashboard />,
    },
    {
        to: "/termsconditions/",
        component: <TermsConditiions />,
    },

]

export const menus = [
    {
        icon: <WindowsLogo />,
        label: "Check In",
        to: "/",
        component: <Dashboard />,
        hide: true
    },
    {
        icon: <WindowsLogo />,
        label: "Dashboard",
        to: "/dashbord/",
        component: <Dashboard />,
        permission: "*"
    },
    {
        icon: <WindowsLogo />,
        label: "Profile",
        to: "/profile/",
        component: <Profile />,
        hide: true
    },
    {
        icon: <WindowsLogo />,
        label: "Settings",
        to: "/settings/",
        component: <Settings />,
        hide: true,
        permission: "*",
    },

    {
        icon: <Key />,
        label: "Permissions",
        to: "/profile/",
        permission: "permission",
        submenus: [
            {
                icon: <CirclesThreePlus />,
                label: "Role",
                to: "/role/",
                component: <Role />,
                permission: "permission",
            },
            {
                icon: <Cube />,
                label: "Assign User To Role",
                to: "/user/role/",
                component: <UserRole />,
                permission: "permission",
            },

        ]
    },
    {
        icon: <UserGear/>,
        label: "User Management",
        to: "/users/",
        component: <Profile />,
        permission: "permission",
        submenus: [
            {
                icon: <User />,
                label: "User",
                to: "/user/",
                component: <Users />,
                permission: "user",
            },
        ]
    },

    {
        icon:<CurrencyCircleDollar />,
        label: "Payments & Orders",
        to: "/payments/",
        component: <Payments />,
        permission: "permission",
    }
]