import React from "react";
import useResponsiveFontSize from "./useResponsiveFontSize";

const ResponsiveText = ({ children }) => {
  const baseFontSize = useResponsiveFontSize();

  // Calculate font sizes for different elements
  const headingFontSize = `calc(${baseFontSize} * 1.5)`; // 1.5x base font size
  const paragraphFontSize = baseFontSize; // Same as base font size
  const BoxFontSize = `calc(${baseFontSize} * .7)`; // 1.5x base font
  const HFontSize = `calc(${baseFontSize} * .90)`; // 1.5x base font
  const BOldFontSize = `calc(${baseFontSize} * .8)`; // 1.5x base font
  return (
    <div>
      <style>
        {`
           h4, h5, h6 {
            font-size: ${headingFontSize};
          }
            h1 {
            font-size: ${BoxFontSize};
          }
          p {
            font-size: ${paragraphFontSize};
            font-weight:"normal";    
          }
             h3 {
            font-size: ${HFontSize};
            font-weight:"normal";    
          }
              Text-bold{
            font-size: ${BOldFontSize};
            font-weight:"normal";    
          }
        `}
      </style>
      {children}
    </div>
  );
};

export default ResponsiveText;