import React from "react";
import { Container } from "react-bootstrap";
export default function Footer({ icon, name }) {
  const comapny = () => {
    try {
      let main = JSON.parse(localStorage.getItem("settings") || "{}");
      return (
        <>
        
          <footer className="d-flex box-footer text-center text-center mt-1 w-100  position-fixed  position-md-static "
           
           style={{
              bottom: 0, // Required for position-fixed
              width: "100%",
              bottom: 0,
             
              // height: 75,
              zIndex: 1000, // Ensure it stays on top
            }}
          > 
            <div
              style={{
                bottom: 0, // Required for position-fixed
                width: "100%",
                // zIndex: 1000, // Ensure it stays on top
              }}
            >
              <img src={icon ? icon : main?.icon}
              style={{
                marginTop:"5px",
                height: "50px", // Adjust height
                width: "250px", // Adjust width
                objectFit: "contain", // Maintain aspect ratio
              }} />
              <div
                // className="mb-2 h-75"
                dangerouslySetInnerHTML={{ __html: name ? name : main?.name }}
              ></div>
              <div className="d-flex justify-content-center mb-2 ">
                <div className="notes2">@All Rights reserved &nbsp; </div>
                <div className="notes2">
                  Powered by{" "}
                  <span style={{ textTransform: "italic" }}>icrats</span>
                </div>
              </div>
            </div>
            </footer>
        </>
      );
    } catch (error) {}
  };

  return <>{comapny()}</>;
}
