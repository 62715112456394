import common from '../saga/reducer';
import { takeEvery, takeLatest } from 'redux-saga/effects';
import { setRequest, _get, _post, _put, _patch, _del, _search, getUser } from './saga'

const methods = ["GET", "POST", "PUT", "PATCH", "DELETE", "SEARCH", "AUTH"]
// ---   update this bellow code----

export const createAction = (MODULE, url, options, initreduce_name = null) => {
    let Make = {}
    const reduce_name = initreduce_name ? initreduce_name : MODULE.toLowerCase()
    let props = {}
    methods.map(method => {
        props[method] = `${MODULE}_${method}`
        props["props"] = { "URL": url, ...options }
        let initialState = {
            loading: false,
            message: "",
            success: false,
        }
        initialState[reduce_name] = {}
        props['reducer'] = function (state = initialState, action = {}) {
            return common(state, action, MODULE)
        }
        props["reduce_name"] = reduce_name
    })
    Make[MODULE] = props
    return Make
}

export const createSaga = (REQUESTS) => {
    setRequest(REQUESTS)

    let sagas = Object.keys(REQUESTS).map((key) => {
        if (typeof key !== "string") return null
        let obj = {}
        obj[key] = function* () {
            yield takeLatest(REQUESTS[key].GET, _get);
            yield takeLatest(REQUESTS[key].POST, _post);
            yield takeLatest(REQUESTS[key].PUT, _put);
            yield takeLatest(REQUESTS[key].PATCH, _patch);
            yield takeLatest(REQUESTS[key].DELETE, _del);
            yield takeLatest(REQUESTS[key].SEARCH, _search);
        }
        return obj[key]()
    })
    const auth = function* () {
        yield takeEvery("LOGIN_AUTH", getUser);
    }
    const logout = function* () {
        yield takeEvery("LOGOUT_AUTH", getUser);
    }
    sagas.push(auth())
    sagas.push(logout())
    return sagas
}


export const createReducer = (REQUESTS) => {
    let reduce = {}
    Object.keys(REQUESTS).map((key) => {
        if (typeof key !== "string") return null
        let reduce_name = REQUESTS[key]["reduce_name"]

        reduce[`${reduce_name}State`] = REQUESTS[key]?.reducer
        return
    })
    return reduce
}

