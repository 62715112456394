import React, { useState } from "react";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import RFG from "../../libs/inputs/RFG";
import { SETTINGS } from "../../services/actions";
import {
  Card,
  CardBody,
  Button,
  Container,
  Col,
  Row,
  CardFooter,
  Badge,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { LOGIN } from "../../services/actions";
import {
  XCircle,
  CheckCircle,
  WarningCircle,
  ShareNetwork,
} from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import Footer from "../public_payment/Footer";
import au from "../cashfree/audio.mp3";
import { handleShare } from "../../libs/share/CaptureShare";

export default function Warnings() {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const settingsState = useSelector((state) => state?.settingsState);
  const [main, setMain] = React.useState();
  let userState = useSelector((state) => state?.login);
  let refDiv = React.useRef(null);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const { handleSubmit, control } = useForm({
    defaultValues: { username: "admin@gmail.com", password: "Admin@123" },
    mode: "all",
  });

  function playSound(url) {
    const audio = new Audio(url);
    audio.play();
  }
  function toLocalTime(utcDateString) {
    const utcDate = new Date(utcDateString);

    // Convert the UTC date to local time
    const localDate = utcDate.toLocaleString();
    return localDate;
  }
   React.useEffect(() => {
      
      dispatch({ type: `${SETTINGS}_GET`, payload: 1 });
    }, []);
  React.useEffect(() => {
    let mainStr = settingsState?.data?.data?.main;
    let main = typeof mainStr === "string" ? JSON.parse(mainStr) : mainStr;
    setMain(main);
    localStorage.setItem("settings", JSON.stringify(main));
  }, [settingsState]);

  const renderMessage = () => {
    let url,
      key,
      dataStr,
      data,
      SUCCESS,
      PENDING,
      USER_DROPPED,
      FAILED = "";
    try {
      url = window.location.href;
      key = "od=";
      dataStr = url.substring(url.indexOf(key) + key.length, url.length);
      data = JSON.parse(atob(dataStr));
      console.log("DATA", data);
      SUCCESS = window.location.href.indexOf("SUCCESS") > 0;
      PENDING = window.location.href.indexOf("PENDING") > 0;
      USER_DROPPED = window.location.href.indexOf("USER_DROPPED") > 0;
      FAILED = window.location.href.indexOf("FAILED") > 0;
    } catch (err) {
      console.error(err);
      return (
        <>
          <div className="text-center text-warning my-4">
            <WarningCircle fontSize={80} />
            <h5>Transaction failed</h5>

            <div className="my-3">Something Went wrong</div>
          </div>
        </>
      );
    }
    if (SUCCESS) {
      return (
        <>
          <div className="text-center text-success my-1">
            <CheckCircle fontSize={80} />
            <div className="my-1">
              <Badge>{data?.payment_amount} INR</Badge>
            </div>
            <h5>Your payment was successful</h5>
            <div className="my-3">Thank you for your payment</div>
          </div>
          <div className="text-center">
            <div className="my-1">Transaction ID :{data?.order_id}</div>
            <div className="my-1">Receipt No :{data?.receipt_no}</div>
            <div className="my-1">Payment ID :{data?.cf_payment_id}</div>
            <div className="my-1">
              Date :{toLocalTime(data?.payment_completion_time)}
            </div>
          </div>
        </>
      );
    }
    if (PENDING) {
      return (
        <>
          <div className="text-center text-warning my-4">
            <WarningCircle fontSize={80} />
            <h5>Transaction pending</h5>

            <div className="my-3">
              Don't worry your money will be safe. will return back your money
              if deducted, Thank you.
            </div>
          </div>
        </>
      );
    }
    if (FAILED) {
      return (
        <>
          <div className="text-center text-danger my-4">
            <XCircle fontSize={80} />
            <h5>Transaction failed</h5>

            <div className="my-3">{data?.error_description_raw}</div>
          </div>
        </>
      );
    }
  };
  return (
    <>
      <Container fluid className="g-0   " ref={refDiv}>
        <Row className="d-flex justify-content-center   g-0">
          <Col xs={12} sm={8} md={5} xl={4}>
            <Card
              className="app-border app-container "
              style={{ flex: 1, minHeight: "100vh" }}
            >
              <CardBody
                className="mx-1 "
                style={{
                  display: "flex",
                  flexDirection: "column",
                  // minHeight: "100vh",
                }}
              >
                <div className=" ">
                  {/* <h1 className="mb-3 app-title">Payment Portal</h1> */}
                  {renderMessage()}
                </div>
                <div className="text-center" id="shareBtn">
                  <div>
                    <ShareNetwork
                      onClick={() => {
                        document.getElementById("shareBtn").style.display =
                          "none";
                        handleShare(refDiv, "share");
                        setTimeout(function () {
                          document.getElementById("shareBtn").style.display =
                            "block";
                        }, 200);
                      }}
                      size={32}
                      className="mt-3 cursor-pointer"
                    />
                  </div>

                  <div
                    className="d-flex justify-content-center gap-4"
                    id="backBtn"
                  >
                    <Button
                      className="btn btn-danger px-4 mt-5"
                      onClick={() => {
                        if (window.location.href.indexOf("from=history") > 0) {
                          navigate("/history/");
                        } else {
                          navigate("/dashboard/");
                        }
                      }}
                    >
                      Back
                    </Button>
                  </div>
                </div>
                <div className="error">{userState?.message}</div>
              </CardBody>

              <footer
                className=" box-footer text-center text-center   position-md-static "
                style={{
                  bottom: 0, // Required for position-fixed
                  width: "100%",
                  bottom: 0,
                  // borderTop: "2px solid black",
                  // height: 75,
                  zIndex: 1000, // Ensure it stays on top
                }}
              >
                <div
                  className="py-2"
                  style={{
                    bottom: 0, // Required for position-fixed
                    width: "100%",
                    // zIndex: 1000, // Ensure it stays on top
                  }}
                >
                  {/* <img src={icon ? icon : main?.icon}
              style={{
                marginTop:"5px",
                height: "50px", // Adjust height
                width: "250px", // Adjust width
                objectFit: "contain", // Maintain aspect ratio
              }} /> */}

                  {/* <img
                    src={logo}
                    alt="Logo"
                    style={{
                      marginTop: "5px",
                      height: "50px", // Adjust height
                      width: "250px", // Adjust width
                      objectFit: "contain", // Maintain aspect ratio
                    }}
                  /> */}

                  <img
                    src={main?.icon}
                    style={{
                      marginTop: "20px",
                      height: "50px", // Adjust height
                      width: "250px", // Adjust width
                      objectFit: "contain", // Maintain aspect ratio
                    }}
                  />
                  <div className="d-flex justify-content-center ">
                    <div className="notes2">@All Rights reserved &nbsp; </div>
                    <div className="notes2">
                      Powered by{" "}
                      <span style={{ textTransform: "italic" }}>icrats</span>
                    </div>
                  </div>
                </div>
              </footer>
              {/* Footer for larger screens */}
            </Card>
          </Col>
        </Row>
        {/* <Footer /> */}
      </Container>
    </>
  );
}
