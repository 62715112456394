import axios from 'axios';
// const baseURL = baseUrl()
const baseURL = process.env.REACT_APP_URL
axios.defaults.baseURL = baseURL

const updateKey = () => {
    let user = undefined
    try {
        user = JSON.parse(localStorage.getItem("auth") || '{}')
        // console.log("auth session", user)
    } catch (err) { }
    if (user?.token) {
        // console.log("token applied", user)
        axios.defaults.headers.common['Authorization'] = "Token " + user.token
        // console.log("header", axios.defaults.headers.common['Authorization'])
    } else {
        axios.defaults.headers.common['Authorization'] = null
    }
}
const handleError = (error) => {
    console.log("mesasge", error)
    if (error?.message == "Network Error"){
        error = {response:{data:"", status:404}}
        error.response.data = "Network lost please check your internet connetion"
    }else if (error.response.status == 403) {
        return { status: error.response.status, error: true, message: error.response.data }
    }
    if (typeof error?.response?.data == 'object') {
        error.response.data = error.response.data?.message
    }
    return { status: error?.response?.status, error: true, message: error.response.data }
}
export const getRequest = async (url) => {

    updateKey()

    try {
        const todos = await axios.get(url)
        return todos.data;
    } catch (err) {
        return handleError(err)
    }
}

// Create New Todo
export const postRequest = async (url, data) => {
    updateKey()
    if (url == "token/") {
        axios.defaults.headers.common["Authorization"] = null;
    }
    // axios.defaults.headers.common["content-type"] = 'multipart/form-data'
    console.log("post called", url)
    try {
        const todo = await axios.post(url, data)
        return todo.data;
    } catch (err) {
        return handleError(err)
    }
}
export const putRequest = async (url, data) => {
    console.log("put called", url)
    updateKey()
    try {
        const todo = await axios.put(url, data);
        return todo.data;
    } catch (err) {
        return handleError(err)
    }
}
//patch
export const patchRequest = async (url, data) => {
    updateKey()
    console.log("patch called", url)
    try {
        const todo = await axios.patch(url, data);
        return todo.data;
    } catch (err) {
        return handleError(err)
    }
}


// Delete existed todo
export const delRequest = async (url, id) => {
    updateKey()
    console.log(url, id)
    url = `${url}${id}/`
    try {
        let data = await axios.delete(url)
        console.log("DEL DATA", data);
        return id
    } catch (err) {
        return handleError(err)
    }
}

