import React, { useEffect, useState } from "react";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import RFG from "../../libs/inputs/RFG";
import paymentImage from "./payhuman.png";
import logo from "./iCratslogo.png";
import { CardBody, Button, Container, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { LOGIN, SETTINGS, LOAN_NUMBER } from "../../services/actions";
import { CheckSquare, Square } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import Footer from "./Footer";
import ResponsiveText from "../../components/ResponsiveText";

export default function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [checked, setChecked] = React.useState(false);
  const userState = useSelector((state) => state?.login);
  const settingsState = useSelector((state) => state?.settingsState);
  const [main, setMain] = React.useState();
  const { handleSubmit, control, setError, reset } = useForm({
    defaultValues: {},
    mode: "all",
  });

  React.useEffect(() => {
    loadCaptchaEnginge(4, "black", "white", "numbers");
    dispatch({ type: `${SETTINGS}_GET`, payload: 1 });
  }, []);

  React.useEffect(() => {
    let mainStr = settingsState?.data?.data?.main;
    let main = typeof mainStr === "string" ? JSON.parse(mainStr) : mainStr;
    setMain(main);
    localStorage.setItem("settings", JSON.stringify(main));
  }, [settingsState]);

  React.useEffect(() => {
    const data = JSON.parse(localStorage.getItem("data") || "{}");
    if (data?.cellNumber) {
      navigate("/dashboard/", { replace: true });
    }
  }, [localStorage.getItem("data")]);

  return (
    <>
      <Container
        fluid
        className="g-0 w-100 vh-100 "
        style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
      >
        <Row
          className="d-flex g-0 "
          style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
        >
          <Col
            xs={12}
            md={6}
            className=" justify-content-center align-items-center "
          >
            <Container className=" mt-5 mb-5 " style={{ maxWidth: "350px" }}>
              <CardBody className="text-start">
                <h2 className=" app-title">Payment Portal</h2>
                <form
                  className="notes"
                  autoComplete="false"
                  onSubmit={handleSubmit((data) => {
                    if (!validateCaptcha(data?.captcha)) {
                      setError("captcha", {
                        type: "custom",
                        message: "Captcha does not match",
                      });
                      return;
                    }
                    if (!data?.privacypolicy) {
                      setError("captcha", {
                        type: "privacypolicy",
                        message: "required",
                      });
                      return;
                    }
                    dispatch({
                      type: `${LOAN_NUMBER}_GET`,
                      payload: `loan/number/${data.mobile}/`,
                      callback: (res) => {
                        if (res?.is_success) {
                          localStorage.setItem(
                            "temp-data",
                            JSON.stringify(res.data)
                          );
                          navigate("/otp/");
                        } else {
                          navigate("/warning/");
                        }
                      },
                    });
                  })}
                >
                  <RFG
                    name="mobile"
                    errormessage="* Mobile number Required"
                    label="Enter your Registered Mobile Number"
                    control={control}
                    rules={{
                      required: true,
                      maxLength: {
                        value: 10,
                        message: "* Mobile number must be 10 digits",
                      },
                      minLength: {
                        value: 10,
                        message: "* Mobile number must be 10 digits",
                      },
                      pattern: {
                        value: /^[0-9]{10}$/,
                        message: "*Only numeric values are allowed",
                      },
                    }}
                    className="mb-1 "
                  >
                    <InputText
                      type="tel"
                      maxLength="10"
                      autoComplete="false"
                      onInput={(e) =>
                        (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                      }
                    />
                  </RFG>
                  <ResponsiveText>
                    <p
                      className="text-muted medium notes mb-1"
                      style={{ textAlign: "left" }}
                    >
                      We will be sending OTP to the mobile number, have it handy
                    </p>
                  </ResponsiveText>
                  <div className="fw-bold mb-1 notes">Verify You’re Human</div>
                  <label className="d-block mb-2">
                    <LoadCanvasTemplate />
                  </label>
                  <RFG
                    name="captcha"
                    errormessage="* Captcha required"
                    label="Enter the Captcha in the black box"
                    control={control}
                    rules={{ required: true, maxLength: 10 }}
                    className="mb-2 paragraph"
                  >
                    <InputText autoComplete="false" />
                  </RFG>
                  {/* <div className="mb-3  text-muted medium notes">
                    Enter the captcha code in the black box.
                  </div> */}
                  <div className="d-flex  gap-2  note1">
                    <label htmlFor="pp" className="toggle-btn">
                      {checked ? <CheckSquare /> : <Square />}
                    </label>
                    <ResponsiveText>
                      <div>
                        By clicking on proceed, you agree to the
                        <span
                          className="text-primary cursor-pointer"
                          onClick={() => {
                            navigate("/termsconditions/", {
                              state: { index: 0 },
                            });
                          }}
                        >
                          &nbsp; Privacy Policy
                        </span>
                        ,
                        <span
                          className="text-primary cursor-pointer"
                          onClick={() => {
                            navigate("/termsconditions/", {
                              state: { index: 1 },
                            });
                          }}
                        >
                          &nbsp;Disclaimer
                        </span>
                        , and
                        <span
                          className="text-primary cursor-pointer"
                          onClick={() => {
                            navigate("/termsconditions/", {
                              state: { index: 2 },
                            });
                          }}
                        >
                          &nbsp; Terms and Conditions
                        </span>
                        &nbsp; of the company.
                      </div>
                    </ResponsiveText>
                  </div>
                  <RFG
                    name="privacypolicy"
                    errormessage=" * Check the box to agree to the Privacy Policy, Disclaimer, and Terms"
                    label="*"
                    control={control}
                    rules={{ required: true }}
                  >
                    <input
                      id="pp"
                      type="checkbox"
                      style={{ display: "none" }}
                      onClick={(e) => setChecked(e.target.checked)}
                    />
                  </RFG>

                  <div className="d-flex justify-content-center gap-3">
                    <Button
                      type="submit"
                      sx={{
                        backgroundColor: "var(--primary-color)",
                        color: "var(--text-color)",
                        "&:hover": { backgroundColor: "var(--primary-hover)" },
                      }}
                    >
                      Proceed
                    </Button>
                  </div>
                </form>
                <div className="text-danger mt-3">{userState?.message}</div>
              </CardBody>
            </Container>
          </Col>
          {/* Left Section */}
          <Col
            md={6}
            className="d-none d-md-flex flex-column  vh-100"
            // style={{
            //   backgroundColor: "#cad7fe",
            style={{ backgroundColor: "rgba(246, 246, 246, 0.8)" }}
          >
            <Container
              className="d-flex justify-content-end "
              style={{ zIndex: 10 }}
            >
              <img
                src={main?.icon}
                style={{
                  marginTop: "20px",
                  height: "50px", // Adjust height
                  width: "250px", // Adjust width
                  objectFit: "contain", // Maintain aspect ratio
                }}
              />
            </Container>
            <Container
              CL
              style={{
                marginBottom: "100px",
                width: "100%", // Adjust width as needed
                height: "700px", // Adjust height as needed
                overflow: "hidden",
                // position: "relative",
              }}
            >
              <img
                src={paymentImage}
                alt="Decorative"
                className="img-fluid mb-5 "
                style={{
                  marginBottom: "80px",
                  paddingBottom: "20px",
                  width: "100%",
                  height: "auto",
                  maxHeight: "100%",
                  objectFit: "contain",
                }}
              />
            </Container>
          </Col>
          {/* Right Section */}
        </Row>
        {/* Footer for larger screens */}
        <footer
          className=" d-none d-sm-block d-flex box-footer text-center text-center mt-1 w-100  position-fixed  position-md-static "
          style={{
            bottom: 0, // Required for position-fixed
            width: "100%",
            bottom: 0,
            borderTop: "2px solid black",
            // height: 75,
            zIndex: 1000, // Ensure it stays on top
          }}
        >
          <div
            className="my-2"
            style={{
              bottom: 0, // Required for position-fixed
              width: "100%",
              // zIndex: 1000, // Ensure it stays on top
            }}
          >
            {/* <img src={icon ? icon : main?.icon}
              style={{
                marginTop:"5px",
                height: "50px", // Adjust height
                width: "250px", // Adjust width
                objectFit: "contain", // Maintain aspect ratio
              }} /> */}

            {/* <img
              src={logo}
              alt="Logo"
              style={{
                marginTop: "5px",
                height: "50px", // Adjust height
                width: "250px", // Adjust width
                objectFit: "contain", // Maintain aspect ratio
              }}
            /> */}
            <div className="d-flex justify-content-center mb-2 ">
              <div className="notes2">@All Rights reserved &nbsp; </div>
              <div className="notes2">
                Powered by{" "}
                <span style={{ textTransform: "italic" }}>icrats</span>
              </div>
            </div>
          </div>
        </footer>
        {/* Footer for larger screens */}
        <div className="d-md-none d-md-none">
          <Footer />
        </div>
      </Container>
    </>
  );
}
