import React from 'react'
import { Avatar } from 'primereact/avatar';
import { Sidebar, Menu, MenuItem, SubMenu, } from 'react-pro-sidebar';
import { CaretUp, CaretDown, List, } from "@phosphor-icons/react";
import { Divider } from 'primereact/divider';
import { Link } from 'react-router-dom';
import { Stack } from 'react-bootstrap'
import { Routes, Route } from "react-router-dom";
import MyToolBar from '../components/MyToolBar';
import { useSelector } from "react-redux";
import { Splitter, SplitterPanel } from 'primereact/splitter';

export default function Layout(props) {
    let user = useSelector((state) => state?.loginState?.auth)
    const [toggled, setToggled] = React.useState(false);
    const [isMinimize, setIsMinimize] = React.useState(false);
    const menuClasses = { icon: "ps-menu-icon" }
    const [broken, setBroken] = React.useState(window.matchMedia('(max-width: 800px)').matches);
    const menuCss = {
        paddingLeft: 6,
        paddingRight: 6,
        height: 'auto',
        fontSize: 14,
        borderRadius: 8,
        marginBottom: 10,
        color: '#4b5563',
        fontWeight:500,
    }
    const activeMenuCss = {
        color: 'white',
    }
    function removeBaseUrl(url) {
        // Regular expression to match the base URL
        var baseUrlPattern = /^https?:\/\/[a-zA-Z0-9.-]+/;
        // Replace the base URL with an empty string
        var withport = url.replace(baseUrlPattern, '');

        return withport.replace(new RegExp(`.*?${"/"}`), "/").replace("/#", "");
    }
    const url = removeBaseUrl(window.location.href);

    React.useEffect(() => {
        setToggled(!broken)
    }, [])

    let submenus = []
    const ResponsiveSideBar = ({ children }) => {
        if (broken) {
            // mobile side bar
            return <>
                <Sidebar onBackdropClick={() => setToggled(false)} toggled={toggled} breakPoint="all">
                    {children}
                </Sidebar>
            </>

        }
        //full width side bar
        return <>
            <aside className="rounded" style={{ display: (toggled ? "block" : "none1"), maxWidth: toggled ? 250 : 120 }}>
                {children}
            </aside>
        </>

    }
    // ispushsidebar
    const createMenu = () => {
        let permissions = user.permissions.map((permission) => permission.split("_")[1])
        if (props?.info) {
            console.info("Permissions", permissions)
        }
        if (!permissions.includes('*')) { permissions.push("*") }

        return props?.menus?.map((menu, j) => {
            if (menu?.hide) return <></>
            if (menu?.permission && permissions.includes(menu?.permission)) {
                const active = window.location.href.includes(menu?.to)
                let css = active ? { ...menuCss, background: "#0d6efd", color: 'white' } : menuCss
                return <>
                    {(!menu?.submenus) && <MenuItem style={css} component={<Link to={menu?.to} />} key={j}>
                        <Stack direction="horizontal" gap={1}>
                            <div className={(active ? 'ps-menu-icon-active' : '') + ' ps-menu-icon'}> {menu?.icon} </div>
                            {!isMinimize && <div> {menu?.label} </div>}
                        </Stack>
                    </MenuItem>}
                    {menu?.submenus && <SubMenu {...props} style={{ ...menuCss, background: active ? "#0d6efd" : undefined }} label={(!isMinimize) ? menu?.label : null} icon={menu.icon}>
                        {
                            menu?.submenus?.map((submenu, i) => {
                                const subactive = url == submenu?.to
                                let scss = subactive ? { ...menuCss, background: "#0d6efd", color: 'white' } : menuCss
                                submenus.push(submenu)
                                if (submenu?.hide) return <></>
                                return <>
                                    <MenuItem style={scss} component={<Link to={submenu?.to} />} title={submenu?.label} key={i}>
                                        <Stack direction="horizontal" gap={1}>
                                            <div className={(subactive ? 'ps-menu-icon-active' : '') + ' ps-menu-icon'}>{submenu?.icon}</div>
                                            {!isMinimize && <div className='ps-menu-label css-12w9als'>{submenu?.label}</div>}
                                        </Stack>
                                    </MenuItem>
                                </>
                            })
                        }
                    </SubMenu>}
                </>
            }
        }
        )
    }
    const createSideBar = () => <>
        <ResponsiveSideBar>
            <Menu renderExpandIcon={({ open }) => <span>{open ? <CaretUp /> : <CaretDown />}</span>}
                rootStyles={{
                    [`.${menuClasses.icon}`]: {
                        color: '#344cff',
                    },
                }}
                menuItemStyles={{
                    button: ({ level, active, disabled }) => {
                        // only apply styles on first level elements of the tree
                        if (level === 0)
                            return {
                                color: disabled ? '#f5d9ff' : 'black',
                                backgroundColor: active ? 'white' : undefined,
                            };
                    },
                }}
            >

                {createAvatar()}
                {createMenu()}
            </Menu>
        </ResponsiveSideBar>
    </>

    const createAvatar = () => {
        return <div style={{ height: 100, textAlign: 'center', padding: 4, marginBottom: 30 }}>
            <Avatar label={user?.first_name?.slice(0, 2)?.toUpperCase()}
                size="xlarge" shape="circle" image={user?.avatar} />
            <div className='mt-2'>{user?.first_name}</div>
            <Divider />
        </div>
    }

    return (
        <>
            <div className="px-3">
                <MyToolBar appName={props?.appName} logo={props?.logo} user={user} onToggled={() => { setToggled(!toggled); }} onLogout={props.onLogout} />
                <div className="d-flex gap-3 mt-2" style={{ height: '86vh' }}>
                    {createSideBar()}
                    <main className="w-100 rounded bg-light" style={{ height: '86vh', overflow: 'auto' }}>
                        <Routes>

                            {
                                props?.menus?.map((menu, j) => <Route key={j} path={menu.to} element={menu.component} />)
                            }
                            {submenus.map((submenu, j) => <Route key={j} path={submenu.to} element={submenu.component} />)}

                            {/* <Route path="*" element={<NoPage />} /> */}

                        </Routes>
                    </main>
                </div>
            </div>
        </>
    )

}